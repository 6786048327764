import { Box, Typography } from "@mui/material";
import { LAYOUT, useResponsive } from "../../themes/themes";
import Logo from "../../assets/images/course-duration1.png";
import BoxImg from "../../assets/images/course-duration.png";
import HeaderComp from "../../components/header";
import { root, content, smallLogo, logo } from "./style";
import { SlideInLeftAnimation } from "../../components/animations";
import { useAnimationKey } from "../../helper";
import { courseDuration } from "../../constant";

const CourseDuration = () => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animation = useAnimationKey();
  return (
    <Box>
      <HeaderComp
        icon="✨"
        title={courseDuration.title}
        subtitle={courseDuration.subTitle}
      />
      <Box
        sx={[
          isDesktop ? { display: "flex" } : LAYOUT.columnCCenter,
          { gap: 2, m: 5 },
        ]}
      >
        <Box sx={[LAYOUT.flexColumnBetween, root]}>
          <Box sx={{ p: 2, gap: 2 }}>
            {courseDuration.modules.map((item) => (
              <Typography key={item.heading} sx={content}>
                {item.heading}
              </Typography>
            ))}
          </Box>
          <Box sx={[{ p: 2 }]}>
            <img
              src={BoxImg}
              alt="box-image"
              style={smallLogo(isDesktop, isTablet)}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography sx={content}>{courseDuration.footer}</Typography>
            </Box>
          </Box>
        </Box>
        <SlideInLeftAnimation key={animation}>
          <img
            src={Logo}
            alt="about"
            style={logo(isDesktop, isTablet, isMobile)}
          />
        </SlideInLeftAnimation>
      </Box>
    </Box>
  );
};

export default CourseDuration;
