import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

type AnimationTypes = {
  children: React.ReactNode;
};

type TypingTextProps = {
  text: string;
  speed?: number;
};

export const TopAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -200, rotate: -10 }}
      animate={{ opacity: 1, y: 0, rotate: 0 }}
      transition={{
        duration: 1,
        ease: "easeOut",
        delay: 0.2,
      }}
    >
      {children}
    </motion.div>
  );
};

export const RightAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 200, scale: 0.8 }}
      animate={{ opacity: 1, x: 0, scale: 1 }}
      transition={{
        duration: 1.2,
        ease: "easeOut",
        delay: 0.4,
      }}
    >
      {children}
    </motion.div>
  );
};

export const BottomAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100, rotate: 5 }}
      animate={{ opacity: 1, y: 0, rotate: 0 }}
      transition={{
        duration: 1.2,
        ease: "easeOut",
        delay: 0.6,
      }}
    >
      {children}
    </motion.div>
  );
};

export const BounceAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        type: "spring",
        stiffness: 100,
        damping: 10,
        duration: 1,
      }}
    >
      {children}
    </motion.div>
  );
};

export const FlipAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, rotateY: -90 }}
      animate={{ opacity: 1, rotateY: 0 }}
      transition={{
        duration: 1.2,
        ease: "easeInOut",
      }}
    >
      {children}
    </motion.div>
  );
};

export const FadeScaleAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 1,
        ease: "easeOut",
      }}
    >
      {children}
    </motion.div>
  );
};

// Spin Animation
export const SpinAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, rotate: -180 }}
      animate={{ opacity: 1, rotate: 0 }}
      transition={{
        duration: 1.2,
        ease: "easeOut",
      }}
    >
      {children}
    </motion.div>
  );
};

export const ZoomOutAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 1.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 1.2,
        ease: "easeOut",
      }}
    >
      {children}
    </motion.div>
  );
};

export const PulseAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={{ scale: [1, 1.05, 1] }}
      transition={{
        duration: 0.8,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
      }}
    >
      {children}
    </motion.div>
  );
};

export const SlideInLeftAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        duration: 1.2,
        ease: "easeOut",
      }}
    >
      {children}
    </motion.div>
  );
};

export const RotateAndFadeAnimation = ({ children }: AnimationTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, rotate: -45 }}
      animate={{ opacity: 1, rotate: 0 }}
      transition={{
        duration: 1.2,
        ease: "easeOut",
      }}
    >
      {children}
    </motion.div>
  );
};

export const TypingText = ({ text, speed = 50 }: TypingTextProps) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setDisplayedText((prev) => prev + text.charAt(index));
      index++;
      if (index === text.length) {
        clearInterval(intervalId);
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  return <>{displayedText}</>;
};
