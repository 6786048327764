import { COLORS } from "../../themes/themes";

export const root = {
  background: COLORS.SemiTransparentWhite,
  borderRadius: "20px",
  flex: 1,
};

export const content = {
  background: COLORS.Crimson,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontSize: { xs: 16, md: 20 },
};

export const smallLogo = (isDesktop: boolean, isTablet: boolean) => ({
  width: isDesktop ? 500 : isTablet ? 250 : 150,
  objectFit: "contain" as "contain",
});

export const logo = (
  isDesktop: boolean,
  isTablet: boolean,
  isMobile: boolean
) => ({
  objectFit: "contain" as "contain",
  width: isDesktop ? 550 : isTablet ? 400 : 200,
  height: isMobile ? 200 : isTablet ? 400 : undefined,
});
