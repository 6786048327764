import { useState, useEffect, Fragment } from "react";
import { motion, AnimatePresence } from "framer-motion";
import NavBarCP from "../../components/navbar/Navbar";
import HeroScreen from "../Hero/Hero";
import AboutThisCourse from "../AboutThisCourse/AboutThisCourse";
import CourseDuration from "../CourseDuration/CourseDuration";
import BuildRealProjects from "../BuildRealProjects/BuildRealProjects";
import PricingPlan from "../PricePlan/PricingPlans";
import RealWorldProjects from "../RealWorldProjects/RealWorldProjects";
import WhyNextGen from "../Why/Why";
import Contact from "../Contact/Contact";
import { Box } from "@mui/material";

const sections = [
  { id: "section1", content: <HeroScreen /> },
  { id: "section2", content: <AboutThisCourse /> },
  { id: "section3", content: <CourseDuration /> },
  { id: "section4", content: <BuildRealProjects /> },
  { id: "section5", content: <PricingPlan /> },
  { id: "section6", content: <RealWorldProjects /> },
  { id: "section7", content: <WhyNextGen /> },
  { id: "section8", content: <Contact /> },
];

const animationVariants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  exit: { opacity: 0, x: -50, transition: { duration: 0.8 } },
};

const Home = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      e.preventDefault();

      if (!isScrolling) {
        setIsScrolling(true);

        if (e.deltaY > 0) {
          setCurrentSection((prev) => Math.min(prev + 1, sections.length - 1));
        } else if (e.deltaY < 0) {
          setCurrentSection((prev) => Math.max(prev - 1, 0));
        }

        setTimeout(() => setIsScrolling(false), 800);
      }
    };

    window.addEventListener("wheel", handleScroll, { passive: false });
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [isScrolling, currentSection]);

  const handleSelectedPageChange = (index: number) => {
    setCurrentSection(index);
  };

  return (
    <Fragment>
      <NavBarCP
        selectedPage={currentSection}
        setSelectedPage={handleSelectedPageChange}
      />
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          minHeight: "100vh",
          height: "auto",
          flex: 1,
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={sections[currentSection].id}
            variants={animationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            style={{
              position: "relative",
              width: "100%",
            }}
          >
            {sections[currentSection].content}
          </motion.div>
        </AnimatePresence>
      </Box>
    </Fragment>
  );
};

export default Home;
