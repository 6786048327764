import { COLORS } from "../../themes/themes";

export const subRoot = (isDesktop: boolean) => ({
  background: COLORS.SemiTransparentWhite,
  borderRadius: "20px",
  flex: 1,
  display: "flex",
  flexDirection: isDesktop ? "row" : "column",
  justifyContent: "space-between",
});

export const heading = {
  background: COLORS.Crimson,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontSize: { xs: 16, md: 20 },
  mt: 2,
};

export const img = (
  isDesktop: boolean,
  isTablet: boolean,
  isMobile: boolean
) => ({
  objectFit: "contain" as "contain",
  width: isDesktop ? 550 : isTablet ? 300 : 150,
  height: isMobile ? 100 : isTablet ? 300 : 500,
});
