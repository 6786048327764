import { Box, Typography } from "@mui/material";
import { LAYOUT, useResponsive } from "../../themes/themes";
import Logo from "../../assets/images/about-1.png";
import BoxImg from "../../assets/images/box.png";
import Img2 from "../../assets/images/about2.png";
import HeaderComp from "../../components/header";
import { content, contentCtn, logo, root, smallImg } from "./style";
import {
  FadeScaleAnimation,
  ZoomOutAnimation,
} from "../../components/animations";
import { useAnimationKey } from "../../helper";
import { aboutSection } from "../../constant";

const AboutThisCourse = () => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animationKey = useAnimationKey();
  return (
    <Box>
      <HeaderComp icon="👨‍💻" title={aboutSection.title} />
      <Box sx={[isDesktop ? { display: "flex" } : LAYOUT.columnCCenter, root]}>
        {isTablet !== true && (
          <ZoomOutAnimation key={animationKey}>
            <Box sx={{ mt: 2 }}>
              <img
                src={Logo}
                alt="about"
                style={logo(isDesktop, isTablet, isMobile)}
              />
            </Box>
          </ZoomOutAnimation>
        )}
        <Box sx={[LAYOUT.flexColumnBetween, contentCtn]}>
          <Box sx={{ p: 2, gap: 2 }}>
            <Typography sx={content}>{aboutSection.heading}</Typography>
            <Typography sx={content}>{aboutSection.subHeading}</Typography>
          </Box>
          <FadeScaleAnimation key={animationKey}>
            <Box sx={[LAYOUT.flexBetween]}>
              <img
                src={Img2}
                alt="about-image"
                style={smallImg(isDesktop, isTablet)}
              />
              <img
                src={BoxImg}
                alt="box-image"
                style={smallImg(isDesktop, isTablet)}
              />
            </Box>
          </FadeScaleAnimation>
        </Box>
        {isTablet === true && (
          <ZoomOutAnimation key={animationKey}>
            <Box sx={{ mt: 2 }}>
              <img
                src={Logo}
                alt="about"
                style={logo(isDesktop, isTablet, isMobile)}
              />
            </Box>
          </ZoomOutAnimation>
        )}
      </Box>
    </Box>
  );
};

export default AboutThisCourse;
