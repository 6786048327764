import { COLORS } from "../../themes/themes";

export const root = { gap: 2, m: 5 };

export const logo = (
  isDesktop: boolean,
  isTablet: boolean,
  isMobile: boolean
) => ({
  objectFit: "contain" as "contain",
  width: isDesktop ? 450 : isTablet ? 400 : 150,
  height: isMobile ? 150 : isTablet ? 300 : undefined,
});

export const smallImg = (isDesktop: boolean, isTablet: boolean) => ({
  width: isDesktop ? 200 : isTablet ? 150 : 80,
  objectFit: "contain" as "contain",
});

export const content = {
  background: COLORS.Crimson,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontSize: { xs: 16, md: 20 },
};

export const contentCtn = {
  background: COLORS.SemiTransparentWhite,
  borderRadius: "20px",
  flex: 1,
};
