import HtmlImg from "../assets/images/html.png";
import CssImg from "../assets/images/css.png";
import JsImg from "../assets/images/js.png";

export const heroSection = {
  heading: "Unlock Your Coding Potential with NextGen Coders Program!",
  subHeading:
    "Learn Web & Mobile App Development (Android & iOS) from Scratch!",
  button: "Join Now | Learn More",
};

export const aboutSection = {
  title: "Learn Web & Mobile App Development with Expert Guidance!",
  heading:
    "Siddharth Jain brings his industry experience to provide a hands-on learning experience. Whether you’re a beginner or looking to refine your skills, our program covers essential technologies, including HTML5, CSS, JavaScript, React.js, React Native, and more.",
  subHeading:
    "Duration: 365 days of comprehensive learning | Language: Hindi | Schedule: Daily 1-hour classes (Mon-Fri), Sat for doubt sessions, Sun off | Online Learning: Interactive classes via Google Meet using Visual Studio Code.",
};

export const courseDuration = {
  title: "Course Curriculum & Duration",
  subTitle:
    "Our program is divided into different modules, each focusing on a crucial part of web and mobile app development.",
  modules: [
    { heading: "HTML5: 30 Days" },
    { heading: "CSS: 60 Days" },
    { heading: "JavaScript: 90 Days" },
    { heading: "React.js & React Native: 185 Days" },
    {
      heading:
        "Additional Tools: GitHub, GitLab, Redux Toolkit, Material UI, Bootstrap, etc.",
    },
  ],
  footer: "Explore Full Curriculum | Get Started Now",
};

export const buildRealProject = {
  title: "Build Real Projects",
  subtitle:
    "At NextGen Coders Program, we believe in learning by doing. You won’t just watch tutorials – you’ll build real, functioning websites and apps. By the end of the course, you’ll have a portfolio that includes:",
  content: [
    {
      content: "Personal Portfolio Website: Showcase your skills and projects.",
    },
    {
      content:
        "E-commerce Store: Build a fully functioning online store with a shopping cart and checkout system.",
    },
    {
      content:
        "Social Media App: Create an app where users can post updates, like, and comment.",
    },
    {
      content:
        "Blog Platform: Develop a blog site with user authentication and post management.",
    },
    {
      content:
        "Weather Forecast App: Display real-time weather data for any location.",
    },
    {
      content:
        "To-Do List App: Manage daily tasks with deadlines and completion tracking.",
    },
    { content: "Movie Review App: Browse movies and post user reviews." },
    {
      content:
        "Real Estate Listings Website: Show property listings with search and filter options.",
    },
    {
      content:
        "Expense Tracker App: Track daily expenses and manage monthly budgets.",
    },
    {
      content:
        "Fitness Tracker App: Log workouts and monitor progress over time.",
    },
  ],
  img: [{ img: HtmlImg }, { img: CssImg }, { img: JsImg }],
  heading: "Project Details",
};

export const pricingPlan = {
  title: "Affordable Pricing Plans",
  subTitle:
    "We offer flexible payment plans to fit your needs. Choose between daily, monthly, or yearly options.",
  content: [
    {
      title: "Daily Package",
      description: "₹200/One hour per day. Ideal for short-term learners.",
      details: "Pay before each session",
    },
    {
      title: "Monthly Package",
      description: "₹5500/Monthly. Great for consistent learning.",
      details: "Pay in advance",
    },
    {
      title: "Yearly Package",
      description: "₹60000/Yearly. Perfect for long-term commitment.",
      details: "Pay in advance",
    },
  ],
  footer: "Enroll Today | Contact for More Details",
};

export const realWorldPro = {
  title: "Real-World Projects to Build Your Portfolio",
  subtitle:
    "Gain practical experience by building real-world projects. You'll work on more than 40 projects during the course to solidify your skills.",
  content: [
    {
      content: "HTML5 Projects: 10+ Projects (e.g., Personal Portfolio, Blog)",
    },
    { content: "CSS Projects: 10+ Projects (e.g., Responsive Websites)" },
    {
      content:
        "JavaScript Projects: 10+ Projects (e.g., To-Do App, Calculator)",
    },
    {
      content:
        "React.js & React Native Projects: 10+ Projects (e.g., E-commerce App, Social Media App)",
    },
  ],
  footer: "See Project Examples | Start Building Now",
};

export const why = {
  title: "Why NextGen Coders Program?",
  content: [
    {
      content:
        "- **Job Opportunities**: Learn how to leverage LinkedIn and showcase your skills to land job offers.",
    },
    {
      content:
        "- **Debugging & Problem Solving**: Master debugging across languages and solve real coding challenges.",
    },
    {
      content:
        "- **Comprehensive Notes**: Access detailed notes for every programming language covered in the course.",
    },
    {
      content:
        "- **Mock Exams**: Test your skills with question papers and mock examinations.",
    },
  ],
  footer: "Get More Info | Enroll Now",
};
