export const aboutThisCourse = [
  {
    name: "Extra Perks",
    description:
      "Learn how to leverage your skills on LinkedIn for job opportunities based on my experience working with international companies.",
  },
  {
    name: "How to Enroll",
    description:
      "To enroll, contact me via email at nextgencodersprogram@gmail.com.",
  },
  {
    name: "Mode of Learning",
    description:
      " Classes will be conducted online using Visual Studio Code (VS Code) and Google Meet for interactive and hands-on learning.",
  },
  {
    name: "Course Details",
    description:
      "Course Details: 📅 Opening Date: May 21, 2024 ⏰ Class Duration: 1 hour daily/session [Mon to Fri], [Sat Doubt class] or [Sun Holiday] 🌈 Languages Available: Hindi 💻 Requirements: Each student must have a laptop or Good internet/wifi connection.💰 Pricing Options: Daily Per Hour: ₹200/person Pay before class start. Monthly Package: ₹5500 Every month pay in advance.",
  },
  {
    name: "🎓 Batches Available",
    description: "Choose between Afternoon and Evening batches or Morning.",
  },
  {
    name: "🚦 Behavior and Conduct",
    description:
      "Maintain a respectful learning environment to ensure everyone's progress",
  },
  {
    name: "💵 Refund Policy",
    description:
      "Fees are non-refundable post-enrollment, promoting commitment and fairness to all participants",
  },
  {
    name: "💪 Commitment to Excellence",
    description:
      "Receive quality education and guidance throughout the course.",
  },
  {
    name: "🌟 Job Prospects",
    description:
      "While success depends on your effort, you'll be well-prepared for job opportunities.",
  },
  {
    name: "⏰ Learning Batch Time",
    description:
      "Batch timings will be finalized based on enrolled participants' preferences.",
  },
  {
    name: "🌟 Customized Learning Paths",
    description:
      "Tailor your journey based on the web, Android, iOS development, or a combination.",
  },
];

export const labelData = [
  { icon: "📅", label: "Classes Start: May 21, 2024" },
  { icon: "🖥️", label: "Daily Sessions (Mon to Fri)" },
  { icon: "🌐", label: "Online Classes" },
];
