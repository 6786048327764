import { COLORS, LAYOUT, useResponsive } from "../../themes/themes";
import { Box, Typography } from "@mui/material";
import { TopAnimation } from "../animations";
import { useAnimationKey } from "../../helper";

const HeaderComp = ({ icon, title, subtitle }: any) => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animationKey = useAnimationKey();

  return (
    <TopAnimation key={animationKey}>
      <Box sx={[LAYOUT.flexCCenter]}>
        <span style={{ fontSize: isDesktop ? 40 : 20 }}> {icon} </span>
        <Typography
          sx={{
            background: COLORS.GRY_RGB,
            WebkitTextFillColor: "transparent",
            WebkitBackgroundClip: "text",
            fontSize: { xs: 20, md: 30 },
            width: isMobile ? 200 : undefined,
            textAlign: isMobile ? "center" : undefined,
          }}
        >
          {" "}
          {title}{" "}
        </Typography>
        <span style={{ fontSize: isDesktop ? 40 : 20 }}> {icon} </span>
      </Box>
      {subtitle && (
        <Box sx={[LAYOUT.flexCCenter]}>
          <Typography
            sx={{
              color: COLORS.WHITE,
              width: isMobile ? 200 : isTablet ? 600 : undefined,
              textAlign: isMobile ? "center" : isTablet ? "center" : "center",
              fontSize: isDesktop ? 25 : isTablet ? 20 : 12,
              mt: 2,
            }}
          >
            Our program is divided into different modules, each focusing on a
            crucial part of web and mobile app development.
          </Typography>
        </Box>
      )}
    </TopAnimation>
  );
};

export default HeaderComp;
