import { Box, Typography } from "@mui/material";
import { LAYOUT, useResponsive } from "../../themes/themes";
import Logo from "../../assets/images/why.png";
import HeaderComp from "../../components/header";
import { why } from "../../constant";
import { heading, img, subRoot } from "./style";
import { SpinAnimation, TypingText } from "../../components/animations";
import { useAnimationKey } from "../../helper";

const WhyNextGen = () => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animation = useAnimationKey();
  return (
    <Box>
      <HeaderComp icon="🌟" title={why.title} />
      <Box
        sx={[
          isDesktop ? { display: "flex" } : LAYOUT.columnCCenter,
          { gap: 2, m: 5 },
        ]}
      >
        <Box sx={subRoot(isDesktop)}>
          <Box
            sx={[
              LAYOUT.flexColumnBetween,
              {
                gap: 2,
                justifyContent: !isDesktop ? "center" : "normal",
                alignItems: !isDesktop ? "center" : "normal",
              },
            ]}
          >
            <SpinAnimation key={animation}>
              <img
                src={Logo}
                alt="about"
                style={img(isDesktop, isTablet, isMobile)}
              />
            </SpinAnimation>
          </Box>
          <Box sx={[LAYOUT.flexColumnBetween]}>
            <Box sx={{ p: 2, gap: 2 }}>
              {why.content.map((item, i) => (
                <Typography key={i} sx={heading}>
                  <TypingText text={item.content} key={animation} speed={50} />
                </Typography>
              ))}
            </Box>
            <Box sx={[{ p: 2, display: "flex", justifyContent: "flex-end" }]}>
              <Typography sx={heading}>{why.footer}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WhyNextGen;
