import { Box, Button, Typography } from "@mui/material";
import { LAYOUT, useResponsive } from "../../themes/themes";
import Logo from "../../assets/images/contact.png";
import HeaderComp from "../../components/header";
import { btnText, button, content, heading, img, input } from "./style";
import { useAnimationKey } from "../../helper";
import { FadeScaleAnimation } from "../../components/animations";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animation = useAnimationKey();

  const form: any = useRef();
  const notify = () =>
    toast("Submit Successfully! We will meet shortly.", { type: "success" });
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [formData, setFormData] = useState({
    to_name: "",
    from_name: "",
    message: "",
  });

  useEffect(() => {
    const timestamp = localStorage.getItem("formSubmitTime");
    if (timestamp) {
      const timePassed = Date.now() - parseInt(timestamp, 10);
      if (timePassed < 48 * 60 * 60 * 1000) {
        setIsDisabled(true);
      }
    }
  }, []);

  const handleInputChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e: any) => {
    e.preventDefault();
    if (
      isDisabled ||
      !formData.to_name ||
      !formData.from_name ||
      !formData.message
    ) {
      return;
    }
    setIsLoading(true);
    emailjs
      .sendForm(
        "service_nz1dnbb",
        "template_w0z3u62",
        form.current,
        "JOMphylOCa_UQxkWz"
      )
      .then(
        () => {
          notify();
          setIsDisabled(true);
          localStorage.setItem("formSubmitTime", Date.now().toString());
          setFormData({
            to_name: "",
            from_name: "",
            message: "",
          });
        },
        (error: any) => {
          console.log("FAILED...", error.text);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <HeaderComp icon="📩" title=" How to Enroll and Contact Us" />
      <Box
        sx={[
          isDesktop
            ? { display: "flex", justifyContent: "space-around" }
            : LAYOUT.columnCCenter,
          { gap: 2, m: 5 },
        ]}
      >
        <FadeScaleAnimation key={animation}>
          <img
            src={Logo}
            alt="about"
            style={img(isDesktop, isTablet, isMobile)}
          />
        </FadeScaleAnimation>
        <Box sx={content}>
          <form
            ref={form}
            onSubmit={sendEmail}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Box sx={{ p: 2, gap: 2 }}>
              <Typography sx={heading}>Full Name</Typography>
              <input
                placeholder="Enter your full name"
                value={formData.to_name}
                onChange={handleInputChange}
                style={input}
                name="to_name"
                required
              />
              <Typography sx={heading}>Email Address</Typography>
              <input
                placeholder="Enter your email address"
                type="email"
                value={formData.from_name}
                onChange={handleInputChange}
                required
                name="from_name"
                style={input}
              />
              <Typography sx={heading}>Messages</Typography>
              <textarea
                placeholder="Enter your message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
                style={input}
              />
              <Button
                sx={button(isDesktop)}
                type="submit"
                disabled={isDisabled || isLoading}
              >
                <Typography sx={btnText}>Submit</Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
