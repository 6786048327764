import { Box, Typography } from "@mui/material";
import { LAYOUT, useResponsive } from "../../themes/themes";
import Logo from "../../assets/images/project.png";
import HeaderComp from "../../components/header";
import { realWorldPro } from "../../constant";
import { heading, img, subRoot } from "./style";
import { ZoomOutAnimation } from "../../components/animations";
import { useAnimationKey } from "../../helper";

const RealWorldProjects = () => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animation = useAnimationKey();
  return (
    <Box>
      <HeaderComp
        icon="🚀"
        title={realWorldPro.title}
        subtitle={realWorldPro.subtitle}
      />
      <Box
        sx={[
          isDesktop ? { display: "flex" } : LAYOUT.columnCCenter,
          { gap: 2, m: 5 },
        ]}
      >
        <Box sx={subRoot(isDesktop)}>
          <Box sx={[LAYOUT.flexColumnBetween]}>
            <Box sx={{ p: 2, gap: 2 }}>
              {realWorldPro.content.map((item: any, i) => (
                <Typography key={i} sx={heading}>
                  {item.content}
                </Typography>
              ))}
            </Box>
            <Box sx={[{ p: 2, display: "flex" }]}>
              <Typography sx={heading}>{realWorldPro.footer}</Typography>
            </Box>
          </Box>
          <Box sx={[LAYOUT.columnCCenter, { gap: 2 }]}>
            <ZoomOutAnimation key={animation}>
              <img
                src={Logo}
                alt="about"
                style={img(isDesktop, isTablet, isMobile)}
              />
            </ZoomOutAnimation>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RealWorldProjects;
