import { Box, Button, Typography } from "@mui/material";
import Logo from "../../assets/images/hero.png";
import { LAYOUT, useResponsive } from "../../themes/themes";
import * as STYLES from "./style";
import { labelData } from "../../db";
import * as Animation from "../../components/animations";
import { useAnimationKey } from "../../helper";
import { heroSection } from "../../constant";

const HeroScreen = () => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const animationKey = useAnimationKey();
  return (
    <Box sx={[LAYOUT.flexColumJCenter]}>
      <Box sx={[LAYOUT.flexRowBetween, STYLES.subRoot(isDesktop)]}>
        <Box sx={[LAYOUT.AlignCenter, STYLES.contentCtn(isDesktop)]}>
          <Animation.FadeScaleAnimation>
            <Typography sx={STYLES.heading(isDesktop)}>
              <span style={STYLES.star}>🌟</span> {heroSection.heading}
              <span style={STYLES.star}>🌟</span>
            </Typography>
          </Animation.FadeScaleAnimation>
          <Animation.BounceAnimation>
            <Typography sx={STYLES.subHeading(isDesktop)}>
              {heroSection.subHeading}
            </Typography>
          </Animation.BounceAnimation>
          <Box sx={[LAYOUT.flexColumn, { gap: 1, mt: 2 }]}>
            {labelData.map((item, i) => (
              <Box
                key={i}
                sx={[
                  LAYOUT.flexRow,
                  { gap: 1, justifyContent: isMobile ? "center" : undefined },
                ]}
              >
                <span>{item.icon}</span>
                <Typography sx={STYLES.mapContent(isDesktop)}>
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
          <Animation.BottomAnimation key={animationKey}>
            <Button sx={STYLES.button(isDesktop)}>
              <Typography sx={STYLES.buttonText}>
                {heroSection.button}
              </Typography>
            </Button>
          </Animation.BottomAnimation>
        </Box>
        <Box sx={[LAYOUT.flexJCenter]} p={isDesktop ? 2 : 0}>
          <Animation.TopAnimation key={animationKey}>
            <img
              src={Logo}
              style={STYLES.logo(isDesktop, isTablet)}
              alt="hero-logo"
            />
          </Animation.TopAnimation>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroScreen;
