import { useState, useEffect } from "react";

export const useAnimationKey = () => {
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    const handlePageReload = () => setAnimationKey((prevKey) => prevKey + 1);
    window.addEventListener("popstate", handlePageReload);
    handlePageReload();
    return () => window.removeEventListener("popstate", handlePageReload);
  }, []);

  return animationKey;
};
