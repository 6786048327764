import { COLORS } from "../../themes/themes";

export const img = (
  isDesktop: boolean,
  isTablet: boolean,
  isMobile: boolean
) => ({
  objectFit: "contain" as "contain",
  width: isDesktop ? 550 : isTablet ? 300 : 150,
  height: isMobile ? 100 : isTablet ? 300 : 500,
});

export const content = {
  background: COLORS.SemiTransparentWhite,
  borderRadius: "20px",
  display: "flex",
  justifyContent: "flex-end",
};

export const heading = {
  color: COLORS.WHITE,
  fontSize: { xs: 16, md: 20 },
  mt: 2,
};

export const input = {
  color: COLORS.BLACK,
  width: "100%",
  padding: "10px",
  height: 40,
  borderRadius: "20px",
  border: "none",
  fontSize: "16px",
  marginTop: 10,
};

export const button = (isDesktop: boolean) => ({
  background: COLORS.SemiTransparentWhite,
  padding: isDesktop ? "10px 20px" : "5px 15px",
  borderRadius: "20px",
  mt: 3,
  width: "300px",
  cursor: "pointer",
});

export const btnText = {
  background: COLORS.Crimson,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textTransform: "capitalize",
  fontWeight: "600",
  fontSize: { xs: 16, md: 20 },
};
