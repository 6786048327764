import { COLORS } from "../../themes/themes";

export const root = {
  backgroundColor: "transparent",
  boxShadow: "none",
};

export const buttonLabel = {
  color: COLORS.WHITE,
  display: "block",
  fontSize: 11,
  padding: "15px 20px",
  textTransform: "capitalize",
};

export const buttonCtn = {
  flexGrow: 1,
  background: COLORS.SemiTransparentWhite,
  borderRadius: "20px",
};

export const heading = (isMobile: boolean) => ({
  mr: 2,
  display: { xs: "flex", md: "none" },
  flexGrow: 1,
  fontFamily: "Poppins, sans-serif",
  fontWeight: 700,
  color: COLORS.WHITE,
  textDecoration: "none",
  fontSize: isMobile ? 16 : 20,
});
